<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <b-card>
          <b-row>
            <b-col sm="4">
              <!-- Parameter -->

              <b-card no-body class="border mt-1">
                <b-card-header class="p-1">
                  <b-card-title class="font-medium-1">
                    <feather-icon icon="GlobeIcon" size="14" />
                    <span class="align-middle ml-50">Balai</span>
                  </b-card-title>
                </b-card-header>
                <b-row class="pl-1 pr-1 mt-1">
                  <b-col>
                    <b-form-group label-cols="4" label-cols-lg="6" label-size="sm" label="Nama Balai" label-for="region_description">
                      <b-form-input id="region_description" v-model="region_description" size="sm" placeholder="Balai" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
              <!--  button car1 -->
              <b-button v-if="insertStatus" variant="primary" @click="submit" class="mb-1 mb-sm-0 mr-0 mr-sm-1" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
                Tambah
              </b-button>
              <b-button v-if="updateStatus" variant="success" @click="update" class="mb-1 mb-sm-0 mr-0 mr-sm-1" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
                Ubah
              </b-button>
              <b-button variant="gradient-danger" @click="cancel" class="mb-1 mb-sm-0 mr-0 mr-sm-1" type="reset" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
                Batal
              </b-button>
            </b-col>

            <b-col sm="8">
              <!-- Parameter TABLE -->
              <b-card no-body class="border mt-1">
                <b-card-header class="p-1">
                  <b-card-title class="font-medium-1">
                    <feather-icon icon="GlobeIcon" size="14" />
                    <span class="align-middle ml-50">Nama Balai</span>
                  </b-card-title>
                </b-card-header>
                <div class="table-responsive text-nowrap">
                  <b-table
                      striped
                      hover
                      responsive
                      :per-page="perPage"
                      :current-page="currentPage"
                      :items="items"
                      :fields="fields"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                    >
                      <template #cell(action)="data">
                        <b-button-group>
                          <b-button variant="gradient-danger" size="sm" @click="onActionDelete(data.item.id)">
                            <trash-icon size="1x" />
                          </b-button>
                          <b-button variant="gradient-primary" size="sm" @click="onActionEdit(data.item)">
                            <edit-2-icon size="1x" />
                          </b-button>
                        </b-button-group>
                      </template>
                    </b-table>
                </div>
              </b-card>
              <!-- Action Buttons -->
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, BCardHeader, BContainer, BRow, BCol, BFormSelect, BForm, BButton, BFormGroup, BCollapse, BFormDatepicker, BFormInput, BTable } from "bootstrap-vue";
import { TrashIcon, Edit2Icon } from "vue-feather-icons";
import axios from 'axios'
import metadata from "@/api/metadata";
import helper from '@/api/helper'
export default {
  components: {
    BCard,
    BCardHeader,
    BContainer,
    BRow,
    BCol,
    BFormSelect,
    BForm,
    BButton,
    BFormGroup,
    BCollapse,
    BFormDatepicker,
    BFormInput,
    BTable,
    TrashIcon,
    Edit2Icon,
  },
  data: function() {
    return {
      items: [],
      region_description : "",
      insertStatus : true,
      updateStatus : false,
      regionID : "",
    };
  },
  methods :{
    cancel(){
      this.region_description = ""
      this.regionID = ""
      this.updateStatus = false;
      this.insertStatus = true;
    },
    onActionEdit(val){
      console.log(val)
      this.region_description = val.Balai
      this.regionID = val.id
      this.updateStatus = true;
      this.insertStatus = false;
    },
    onActionDelete(val){
      this.regionID = val;
      var config = {
        method: 'delete',
        url: helper.getBaseUrl()+val,
        headers: helper.getHeader(),
        data : {"is_delete":1}
      };
      this.$swal({
        title: "Apakah Anda Yakin?",
        text: "Hapus Balai??",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-secondary ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          
          // metadata.deleteRegion(this.regionID)
          axios(config)
            .then((response) => {   
              if (response.status == 201 || response.status == 200 || response.status == 204) {        
                this.$swal({
                  title: "Hapus Sukses",
                  text: "Balai Telah di Hapus",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
                this.region_description = "";
                this.regionID = "";
                this.updateStatus = false;
                this.insertStatus = true;
                this.listRegion()
              }else{
                this.$swal({
                  title: "Hapus Error",
                  text: "Terjadi Kesalahan",
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              }
            
            })
            .catch((error) => {
              this.$swal({
                  title: "Hapus Error",
                  text: "Terjadi Kesalahan",
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
            });
          
        } else if (result.dismiss === "cancel") {
          
          return;
        }
      });
    },
    update(){
      const bodyData = {
        "region_description" : this.region_description
      }
      metadata.patchRegion(bodyData,this.regionID)
        .then((response) => {
         
            this.$swal({
              title: "Ubah Sukses",
              text: "Balai Telah di ubah",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.region_description = "";
            this.regionID = "";
            this.updateStatus = false;
            this.insertStatus = true;
            this.listRegion()
         
        })
        .catch((error) => {
          this.$swal({
              title: "Ubah Error",
              text: "Terjadi Kesalahan",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
        });
    },
    submit(){

      const bodyData = {
        "@type" : "BmkgRegion",
        "region_description" : this.region_description
      }
      if(this.region_description == ""){
        this.$swal({
          title: "Input Error!",
          text: "Nama Region harus diisi",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }else{
        metadata.postRegion(bodyData)
        .then((response) => {
          if (response.status == 201 || response.status == 200 || response.status == 204) {
            this.$swal({
              title: "Input Sukses",
              text: "Region Telah di simpan",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.region_description = ""
            this.listRegion()
          }
        })
        .catch((error) => {
          this.$swal({
              title: "Input Error",
              text: "Terjadi Kesalahan",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
        });
      }
      
    },
    async listRegion(){
      this.items = []
      try{
        const {data} = await metadata.getRegionList()
        console.log(data)
        for(var i = 0; i < data.length; i ++){
          const dataRegion = { 
              id : data[i].region_id,
              Balai : data[i].region_description,
              Action : ""
              }
          this.items.push(dataRegion)
        }
      }catch(e){
        console.log(e)
      }
    },
  },
  mounted(){
    this.listRegion();
  }
};
</script>
